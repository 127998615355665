import type {
  NavigationGuardReturn,
  RouteLocationNormalized,
} from 'vue-router';
import type { UserRole } from '../types';

/**
 * Nuxt middleware for preventing users from accessing
 * admin routes.
 * @example
 * ```ts
 * setup() {
 *   definePageMeta({ layout: 'default', middleware: ['admin'] });
 * }
 * ```
 * @package fun-academy-campus
 */
export default defineNuxtRouteMiddleware(
  async (to: RouteLocationNormalized): Promise<NavigationGuardReturn> => {
    const currentUser = useCurrentUser();
    const localePath = useLocalePath();
    const { $logger } = useNuxtApp();
    if (!currentUser.value) {
      return await navigateTo(
        localePath({
          name: 'sign-in',
          query: { skip: 'loading', redirect: to.fullPath },
        })
      );
    }
    const { getUserClaims, getUserRoles } = useFirebase();
    const roles: UserRole[] = await getUserRoles();
    const adminRole: UserRole | undefined = roles.find(
      (role: UserRole): boolean => 'Admin' === role.name
    );
    const userRoleIds: string[] = (await getUserClaims())?.roles ?? [];
    const isAllowed: boolean = Boolean(
      userRoleIds.includes(String(adminRole?.id))
    );
    if (isAllowed) {
      $logger.info(`Admin access granted for ${currentUser.value.uid} ✅`);
    } else {
      $logger.warn(`Admin access denied for ${currentUser.value.uid} ❌`);
      return await navigateTo(localePath({ name: 'access-denied' }));
    }
  }
);
